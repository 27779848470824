import React from "react";
import { Config } from "@cleeng/mediastore-sdk";
import {
  Purchase as PurchaseComponent,
  Auth as MediastoreAuth,
} from "@cleeng/mediastore-sdk";
import adyenConfiguration from "../Context/adyenConfigurationRedirect";
import { useState, useEffect } from "react";

const PurchaseRedirect = ({ offer }) => {
  const [currentOffer, setCurrentOffer] = useState("S645248091_PL");
  useEffect(() => {
    Config.setEnable3DSRedirectFlow();
  }, []);

  return (
    <div>
      {MediastoreAuth.isLogged() ? (
        <>
          <h1>Purchase</h1>
          <button
            onClick={() => {
              Config.setLanguage("ar");
              console.log("Config.setLanguage(ar)");
            }}
          >
            ar
          </button>
          <button
            onClick={() => {
              Config.setLanguage("en");
              console.log("Config.setLanguage(en)");
            }}
          >
            en
          </button>
          <PurchaseComponent
            // offerId={currentOffer}
            offerId={offer}
            // onSuccess={() => window.location.reload()}
            adyenConfiguration={adyenConfiguration}
          />
        </>
      ) : (
        <>
          <h2>
            In order to see Purchase component you need to be logged in.{" "}
            <br></br>Please login.
          </h2>
        </>
      )}
    </div>
  );
};

export default PurchaseRedirect;
