import React from "react";
import "./Modal.css";
import PropTypes from "prop-types";

const Modal = ({
  onClose,
  setToggleModalVisibility,
  changeConfig,
  env,
  publisher,
  offer,
  refreshPage,
}) => {
  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2 className="modal-title">Config Setup</h2>
        </div>
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              changeConfig(
                e.target[0].value,
                e.target[1].value,
                e.target[2].value
              );
              setToggleModalVisibility(false);
              refreshPage();
            }}
          >
            <section>
              <div className="modal-form-content">
                <label for="env" className="modal-form-content-label">
                  Environment:
                </label>
                <select id="env" type="text" defaultValue={env} name="env">
                  <option value="production">production</option>
                  <option value="sandbox">sandbox</option>
                </select>
              </div>
              <div className="modal-form-content">
                <label for="publisher" className="modal-form-content-label">
                  Publisher:
                </label>
                <input
                  id="publisher"
                  type="text"
                  defaultValue={publisher}
                  name="publisher"
                />
              </div>
              <div className="modal-form-content">
                <label for="offer" className="modal-form-content-label">
                  Offer:
                </label>
                <input
                  id="offer"
                  type="text"
                  defaultValue={offer}
                  name="offer"
                />
              </div>
            </section>
            <section>
              <div className="modal-submit">
                <button className="button" type="submit" value="Submit">
                  Submit
                </button>
              </div>
            </section>
          </form>
        </div>
        <div className="modal-footer">
          <button onClick={onClose} className="button-red">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  toggleModalVisibility: PropTypes.bool,
  setToggleModalVisibility: PropTypes.bool.isRequired,
  changeConfig: PropTypes.func.isRequired,
  env: PropTypes.string.isRequired,
  publisher: PropTypes.number.isRequired,
  offer: PropTypes.string.isRequired,
};

Modal.defaultProps = {
  toggleModalVisibility: false,
};

export default Modal;
