import React, { useContext } from "react";
import {
  PaymentInfo as PaymentInfoComponent,
  Auth as MediastoreAuth,
} from "@cleeng/mediastore-sdk";
import { AuthContext } from "Context/AuthContext";

const PaymentInfo = (props) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  React.useEffect(() => {
    MediastoreAuth.isLogged();
  }, [isAuthenticated]);
  return (
    <div>
      {MediastoreAuth.isLogged() ? (
        <>
          <h1>PaymentInfo</h1>
          <div id="Sub-Component-PaymentInfo">
            <PaymentInfoComponent displayGracePeriodError />
          </div>
        </>
      ) : (
        <>
          <h1>
            In order to see PaymentInfo component you need to be logged in.{" "}
            <br></br>Please login.
          </h1>
        </>
      )}
    </div>
  );
};

export default PaymentInfo;
