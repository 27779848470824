import { Auth as MediastoreAuth } from "@cleeng/mediastore-sdk";
import { AuthContext } from "Context/AuthContext";
import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import Dropdown from "./Dropdown";
import logo from "./premiumplus.png";
const NavitgationBar = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [dropdown, setDropdown] = useState(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  const onSupportLinkClick = () => {
    Cookies.set("supportLinkClicked", true);

    const token = localStorage.getItem("CLEENG_AUTH_TOKEN_PP");
    console.log(token);

    Cookies.set("PP_TOKEN_TEST", token, {
      domain: ".premiumplus.dev",
      secure: true,
      sameSite: "Lax",
    });
    Cookies.set("PP_TOKEN_TEST_LOCAL", token, {
      // domain: "cleengsupport.premiumplus.dev",
      // sameSite: "None",
    });

    window.open("https://cleengsupport.premiumplus.dev", "_blank");
  };

  React.useEffect(() => {
    MediastoreAuth.isLogged();
  }, [isAuthenticated]);

  return (
    <div className="AppAuth">
      <header className="main-menu menu">
        <p className="menu-link">
          <img src={logo} alt="logo" height="36px" />
        </p>

        <ul className="menu">
          <li onClick={() => onSupportLinkClick()}>Help Center</li>
          <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Link
              to="/allLowerComponents"
              className="nav-links"
              data-cy="allLowerComponents"
              // onClick={}
            >
              allLowerComponents
            </Link>
            {dropdown && <Dropdown />}
          </li>
          <li>
            <Link className="nav-links" to="/checkout">
              CheckOut
            </Link>
          </li>
          <li>
            <Link className="nav-links" to="/myaccount">
              MyAccount
            </Link>
          </li>
          <li className="button-adjustment">
            <button
              className="button-red"
              onClick={() => {
                MediastoreAuth.logout();
                window.location.reload(false);
              }}
              data-cy="logoutButton"
            >
              Logout
            </button>
            <button
              className="button-orange"
              onClick={() => {
                localStorage.removeItem("CLEENG_REFRESH_TOKEN");
                localStorage.removeItem("CLEENG_AUTH_TOKEN");
                window.location.reload(false);
              }}
              data-cy="logoutButton"
            >
              Clear
            </button>
          </li>
        </ul>
      </header>
    </div>
  );
};
export default NavitgationBar;
