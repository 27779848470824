import React from "react";
import { Checkout } from "@cleeng/mediastore-sdk";

const CheckOut = ({ offer }) => {
  return (
    <div>
      <h1>Checkout</h1>
      <div id="Sub-Component-Checkout">
        <Checkout offerId={offer} hideCaptcha={true} />
      </div>
    </div>
  );
};

export default CheckOut;
