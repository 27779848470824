import "./reset.css";
import "normalize.css";
import "@cleeng/mediastore-sdk/dist/styles/msdFont.css";
import "./App.css";
import React, { useState } from "react";
import { Config } from "@cleeng/mediastore-sdk";
import NavitgationBar from "Components/NavigationBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CheckOut from "Pages/CheckOut";
import CheckOutWithExtraProps from "Pages/CheckOutWithExtraProps";
import OnePageMS from "Pages/OnePageMS";
import MyAccount from "Pages/MyAccount";
import Register from "Pages/Register";
import Login from "Pages/Login";
import Capture from "Pages/Capture";
import CheckoutConsents from "Pages/CheckoutConsents";
import Purchase from "Pages/Purchase";
import PurchaseRedirect from "Pages/PurchaseRedirect";
import RedeemGift from "Pages/RedeemGift";
import PasswordReset from "Pages/PasswordReset";
import Subscriptions from "Pages/Subscriptions";
import SubscriptionSwitches from "Pages/SubscriptionSwitches";
import PlanDetails from "Pages/PlanDetails";
import PaymentInfo from "Pages/PaymentInfo";
import TransactionList from "Pages/TransactionList";
import UpdateProfile from "Pages/UpdateProfile";
import Modal from "Components/Modal/Modal";
import "@adyen/adyen-web/dist/adyen.css";
import "react-loading-skeleton/dist/skeleton.css";

const App = () => {
  const [toggleModalVisibility, setToggleModalVisibility] = useState(false);
  function refreshPage() {
    window.location.reload(false);
  }

  const [env, setEnv] = useState(() => {
    let env = "sandbox";
    const CLEENG_ENVIRONMENT = localStorage.getItem("CLEENG_ENVIRONMENT");

    if (CLEENG_ENVIRONMENT === null) {
      return env;
    } else if (CLEENG_ENVIRONMENT === "sandbox" || "sandbox") {
      return CLEENG_ENVIRONMENT;
    } else if (env === "sandbox" || "sandbox") {
      return env;
    } else {
      return "sandbox";
    }
  });

  Config.setTermsUrl("https://www.test.com");

  const [publisher, setPublisher] = useState(() => {
    let publisher = "123";
    const PUBLISHER_ID = localStorage.getItem("CLEENG_PUBLISHER_ID");

    if (PUBLISHER_ID === null) {
      return publisher;
    } else if (PUBLISHER_ID && PUBLISHER_ID.length === 9) {
      return PUBLISHER_ID;
    } else if (publisher && publisher.length === 9) {
      return publisher;
    } else {
      return "123";
    }
  });
  const [offer, setOffer] = useState(() => {
    let offer = "S123_PL";
    const CLEENG_OFFER_ID = localStorage.getItem("CLEENG_OFFER_ID");

    if (CLEENG_OFFER_ID === null) {
      return offer;
    } else if (
      (CLEENG_OFFER_ID && CLEENG_OFFER_ID.length === 13) ||
      (CLEENG_OFFER_ID && CLEENG_OFFER_ID.length === 12)
    ) {
      return CLEENG_OFFER_ID;
    } else if (
      (offer && offer.length === 13) ||
      (offer && offer.length === 12)
    ) {
      return offer;
    } else {
      return "S123_PL";
    }
  });

  window.addEventListener("MSSDK:auth-failed", (evt) =>
    console.log("MSSDK:auth-failed")
  );
  window.addEventListener("MSSDK:redeem-button-clicked", (evt) =>
    console.log("MSSDK:redeem-button-clicked" + " " + evt.detail.coupon)
  );

  window.addEventListener("MSSDK:redeem-coupon-success", (evt) => {
    console.log("MSSDK:redeem-coupon-success", evt.detail);
  });
  window.addEventListener("MSSDK:redeem-coupon-failed", (evt) => {
    console.log("MSSDK:redeem-coupon-failed", evt.detail);
  });
  window.addEventListener("MSSDK:good-news-page", (evt) => {
    console.log("MSSDK:good-news-page", evt.detail);
  });
  window.addEventListener("MSSDK:payment-page", (evt) => {
    console.log("MSSDK:payment-page", evt.detail);
  });
  window.addEventListener("MSSDK:switch-popup-action-successful", (evt) => {
    console.log("MSSDK:switch-popup-action-successful", evt.detail);
  });
  window.addEventListener("MSSDK:purchase-successful", (evt) => {
    console.log("MSSDK:purchase-successful", evt);
  });
  window.addEventListener("MSSDK:purchase-failed", (evt) => {
    console.log("MSSDK:purchase-failed", evt.detail);
  });

  const changeConfig = (env, publisher, offer) => {
    setEnv(env);
    setPublisher(publisher);
    setOffer(offer);
  };

  Config.setEnvironment(env);
  Config.setPublisher(publisher.toString());
  Config.setOffer(offer);

  React.useEffect(() => {
    console.log(window.location.search);
  }, [window.location.search]);

  return (
    <div className="App">
      <Router>
        <NavitgationBar setToggleModalVisibility={setToggleModalVisibility} />
        <Routes>
          <Route path="/myaccount" element={<MyAccount />} />
          <Route path="/checkout" element={<CheckOut offer={offer} />} />
          <Route
            path="/allLowerComponents"
            element={<OnePageMS offerId={offer} refreshPage={refreshPage} />}
          />
          <Route path="/Register" element={<Register />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Capture" element={<Capture />} />
          <Route path="/CheckoutConsents" element={<CheckoutConsents />} />
          <Route path="/Purchase" element={<Purchase offer={offer} />} />
          <Route
            path="/CheckOutWithExtraProps"
            element={<CheckOutWithExtraProps offer={offer} />}
          />
          <Route
            path="/PurchaseRedirect"
            element={<PurchaseRedirect offer={offer} />}
          />
          <Route path="/RedeemGift" element={<RedeemGift />} />
          <Route path="/PasswordReset" element={<PasswordReset />} />
          <Route path="/Subscriptions" element={<Subscriptions />} />
          <Route
            path="/SubscriptionSwitches"
            element={<SubscriptionSwitches offer={offer} />}
          />
          <Route path="/PlanDetails" element={<PlanDetails />} />
          <Route path="/PaymentInfo" element={<PaymentInfo />} />
          <Route path="/TransactionList" element={<TransactionList />} />
          <Route path="/UpdateProfile" element={<UpdateProfile />} />
        </Routes>
        {toggleModalVisibility ? (
          <Modal
            changeConfig={changeConfig}
            env={env}
            publisher={publisher}
            offer={offer}
            onClose={() => setToggleModalVisibility(false)}
            setToggleModalVisibility={setToggleModalVisibility}
            refreshPage={refreshPage}
          />
        ) : null}
      </Router>
    </div>
  );
};

export default App;
