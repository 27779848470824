import React from "react";
import { Config } from "@cleeng/mediastore-sdk";
import {
  RedeemGift as RedeemGiftComponent,
  Auth as MediastoreAuth,
} from "@cleeng/mediastore-sdk";
import { useState } from "react";

const RedeemGift = (props) => {
  const [currentOffer, setCurrentOffer] = useState("S645248091_PL");

  return (
    <div>
      {MediastoreAuth.isLogged() ? (
        <>
          <h1>RedeemGift</h1>
          <button
            onClick={() => {
              Config.setLanguage("ar");
              console.log("Config.setLanguage(ar)");
            }}
          >
            ar
          </button>
          <button
            onClick={() => {
              Config.setLanguage("en");
              console.log("Config.setLanguage(en)");
            }}
          >
            en
          </button>
          <RedeemGiftComponent
            onBackClick={() => console.log("Back to the Checkout")}
            onSuccess={() => console.log("success")}
          />
        </>
      ) : (
        <>
          <h2>
            In order to see RedeemGift component you need to be logged in.{" "}
            <br></br>Please login.
            <RedeemGiftComponent
              onBackClick={() => console.log("Back to the Checkout")}
              onSuccess={() => console.log("success")}
            />
          </h2>
        </>
      )}
    </div>
  );
};

export default RedeemGift;
